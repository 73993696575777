import React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

const Seo = ({ pathname }) => {
  const { site } = useStaticQuery(query);
  const canonical = pathname ? `${site.siteMetadata.siteUrl}${pathname}` : null;

  const { defaultTitle, defaultDescription, siteUrl, defaultImage } =
    site.siteMetadata;

  const seo = {
    title: defaultTitle,
    description: defaultDescription,
    image: `${siteUrl}${defaultImage}`,
    url: `${siteUrl}`,
  };

  return (
    <Helmet
      title={`${defaultTitle}`}
      link={
        canonical
          ? [
              {
                rel: "canonical",
                href: canonical,
              },
            ]
          : []
      }
      meta={[
        {
          name: `description`,
          content: defaultDescription,
        },
        {
          property: `og:title`,
          content: "LaPieza.io| Simplifica tu proceso de reclutamiento, perfecto para Startups.",
        },
        {
          property: `og:description`,
          content: defaultDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:url`,
          content: siteUrl,
        },
        {
          property: "og:image",
          content:
            "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/BootcampResources%2FATSpreview.png?alt=media&token=9df48d10-9536-47dd-8c5b-c143f25c5035",
        },
        {
          property: "image",
          content: `${seo.image}`,
        },
        {
          property: "og:image:width",
          content: "50px",
        },
        {
          property: "og:image:height",
          content: "50px",
        },
      ]}
    >
      <link rel="stylesheet" href="https://use.typekit.net/tbx7mwx.css"/>
    </Helmet>
  );
};

export default Seo;

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        defaultDescription: description
        defaultImage: image
        siteUrl: url
      }
    }
  }
`;
