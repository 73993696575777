import React from "react";
import styled from "styled-components";
import "../../styles/globalStyles.scss";
import Navbar from "../reusable/navbar";
import Footer from "./Footer";
import DrawerMenu from "./DrawerMenu";
import Seo from "./Seo";

const Layout = ({ children, className }) => {
  return (
    <div className={className}>
      <Seo/>
      <Navbar />
      <DrawerMenu/>
      {children}
      <Footer />
    </div>
  );
};

export default styled(Layout)`
  max-width: 100%;
  overflow-x: hidden !important;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: max-content;
`;