import React, { useState } from "react";
import styled from "styled-components";
import Drawer from "@mui/material/Drawer";
import { Menu, Close } from "styled-icons/material";
import { Link } from "gatsby";
import { getFlex, linkRouge, palette} from "../../styles/styles";
import { StaticImage } from "gatsby-plugin-image";
import { useI18next, useTranslation } from "gatsby-plugin-react-i18next";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {countryToFlag} from "../../utils/utils";

const DrawerMenu = (props) => {
  const { className } = props;
  const [showDrawerMenu, setShowDrawerMenu] = useState(false);
  const { t } = useTranslation("nav");
  const { changeLanguage, language } = useI18next();
  const languages = [
    { code: "MX", key: "es" },
    { code: "US", key: "en" },
  ];

  const onChange = (e) => {
    const lang = e.target.value;
    changeLanguage(lang);
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setShowDrawerMenu(open);
  };

  return (
    <>
      <div className={className}>
        <span role="button" onClick={toggleDrawer("left", true)}>
          {showDrawerMenu ? (
            <Close size={24} className="icon-option-drawer" />
          ) : (
            <Menu size={24} className="icon-option-drawer" />
          )}
        </span>
          <Link className="logo" to="/">
            <StaticImage src="../../images/logo.png" alt="" />
          </Link>
      </div>
      <Drawer
        className={className}
        anchor="left"
        open={showDrawerMenu}
        onClose={toggleDrawer(false)}
      >
        <div
          role="button"
          className="drawerMenu"
          id="drawerMenu"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <div className="menu">
            <span className="menuItem" onClick={() => {}}>
              <span className="solutions">
                <h2 className="main">{t("solutions")}</h2>
              </span>
            </span>
            <Link className="link" to="/ats">
              <h4>{t("hiringSuite")}</h4>
            </Link>
            <Link className="link" to="/headhunting">
              <h4>{t("executiveSearch")}</h4>
            </Link>
            <Link className="link" to="/pre-screening">
              <h4>{t("autoHunt")}</h4>
            </Link>
            <Link className="link" to="/digital-campus">
              <h4>{t("digitalCampus")}</h4>
            </Link>
            <span className="menuItem" onClick={() => {}}>
              <span className="solutions">
                <h2 className="main"> {t("aboutUs")}</h2>
              </span>
            </span>
            <Link className="link" to="/about-us">
              <h4>{t("whoWeAre")}</h4>
            </Link>
            <Link className="link" to="/usability">
              <h4>{t("usability")}</h4>
            </Link>
            <Link className="link" to="/carbon-neutrality">
              <h4>{t("carbonNeutrality")}</h4>
            </Link>
            <Link className="link" to="/privacy-policy">
              <h4>{t("privacy")}</h4>
            </Link>
            <a
              href="https://ats.lapieza.io/login"
              target="_blank"
              rel="noreferrer"
              className="link"
            >
              {t("signIn")}
            </a>
            <a
              href="https://ats.lapieza.io/authorize-me"
              target="_blank"
              rel="noreferrer"
              className="rouge"
            >
              {t("tryFree")}
            </a>
            <Select className="lang" value={language} onChange={onChange}>
              {languages.map((item) => (
                <MenuItem style={{fontSize: "1.5rem"}} key={item.key} value={item.key}>
                  {`${countryToFlag(item?.code || "")} ${t(item.key)}`}
                </MenuItem>
              ))}
            </Select>
          </div>
          <figure className="logoMenu">
            <StaticImage src="../../images/logo.png" alt="lapieza" />
          </figure>
        </div>
      </Drawer>
    </>
  );
};
export default styled(DrawerMenu)`
  width: 100%;
  @media (max-height: 899px) {
    height: 10vh;
  }
  @media (min-height: 900px) {
    height: 10rem;
  }
  padding: 4% 10%;
  ${getFlex("row", "st", "ct")};
  justify-items: center;
  align-items: center;
  @media (min-width: 901px) {
    display: none;
  }
  .drawerMenu {
    height: fit-content;
    width: fit-content;
    display: grid;
    grid-template-rows: auto;
    grid-row-gap: 4rem;
    .menu {
      height: 100%;
      font-size: 2rem;
      padding: 4rem;
      ${getFlex("column", "st", "st")};
      row-gap: 1.5rem;
      .main{
        font-size: 2.5rem;
        margin: 2rem 0 2rem 0;
      }
      .link{
        text-decoration: none;
        color: ${palette.black};
        h4 {
          font-size: 2rem;
        }
      }
      .rouge {
        ${linkRouge};
      }
      .lang {
        margin-top: 1rem;
        height: 4rem;
        font-size: 2rem;
        font-family: "SofiaProRegularAz", sans-serif;
        border-radius: 2rem;
        border: none;
        text-align: center;
        background-color: ${palette.generalBg};
      }
    }
    .logoMenu {
      width: 80%;
      height: fit-content;
      margin: auto;
    }
  }
  .logo {
    margin: 0 auto;
  }
`;
