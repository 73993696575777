import React, { useState } from "react";
import styled from "styled-components";
import { useI18next, useTranslation } from "gatsby-plugin-react-i18next";
import { StaticImage } from "gatsby-plugin-image";
import { getFlex, linkRouge, menuItem, palette } from "../../styles/styles";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { countryToFlag } from "../../utils/utils";
import { Link } from "gatsby";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

const Navbar = (props) => {
  const { className } = props;
  const { t } = useTranslation("nav");
  const { changeLanguage, language } = useI18next();
  const languages = [
    { code: "MX", key: "es" },
    { code: "US", key: "en" },
  ];

  const onChange = (e) => {
    const lang = e.target.value;
    changeLanguage(lang);
  };

  const handleClose = (el) => {
    switch (el) {
      case 0:
        setAnchorEl(null);
        break;
      case 1:
        setAnchorEl1(null);
        break;
      case 2:
        setAnchorEl2(null);
        break;
    }
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl1, setAnchorEl1] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const open = Boolean(anchorEl);
  const open1 = Boolean(anchorEl1);
  const open2 = Boolean(anchorEl2);

  const handleClick = (event, menu) => {
    switch (menu) {
      case 0:
        setAnchorEl(anchorEl ? null : event.currentTarget);
        break;
      case 1:
        setAnchorEl1(anchorEl1 ? null : event.currentTarget);
        break;
      case 2:
        setAnchorEl2(anchorEl2 ? null : event.currentTarget);
        break;
    }
  };

  return (
    <nav className={className}>
      <Link className="logo" to="/">
        <StaticImage src="../../images/logo.png" alt="" />
      </Link>
      <div className="menu">
        <span className="menuItem" onClick={(e) => handleClick(e, 0)}>
          <span className="solutions">
            {t("solutions")}
            {!open ? (
              <KeyboardArrowDown className="chevron" />
            ) : (
              <KeyboardArrowUp className="chevron" />
            )}
          </span>
          <Menu
            id="firstMenu"
            anchorEl={anchorEl}
            open={open}
            onClose={() => handleClose(0)}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem>
              <Link
                style={{
                  width: "100%",
                  fontSize: "1.8rem",
                  textDecoration: "none",
                  color: palette.black,
                }}
                className="link"
                to="/ats"
              >
                {t("hiringSuite")}
              </Link>
            </MenuItem>
            <MenuItem>
              <Link
                style={{
                  width: "100%",
                  fontSize: "1.8rem",
                  textDecoration: "none",
                  color: palette.black,
                }}
                className="link"
                to="/headhunting"
              >
                {t("executiveSearch")}
              </Link>
            </MenuItem>
            <MenuItem>
              <Link
                style={{
                  width: "100%",
                  fontSize: "1.8rem",
                  textDecoration: "none",
                  color: palette.black,
                }}
                className="link"
                to="/pre-screening"
              >
                {t("autoHunt")}
              </Link>
            </MenuItem>
            <MenuItem>
              <Link
                style={{
                  width: "100%",
                  fontSize: "1.8rem",
                  textDecoration: "none",
                  color: palette.black,
                }}
                className="link"
                to="/digital-campus"
              >
                {t("digitalCampus")}
              </Link>
            </MenuItem>
          </Menu>
        </span>
        <span className="menuItem" onClick={(e) => handleClick(e, 1)}>
          <span className="solutions">
            {t("aboutUs")}
            {!open1 ? (
              <KeyboardArrowDown className="chevron" />
            ) : (
              <KeyboardArrowUp className="chevron" />
            )}
          </span>
          <Menu
            id="firstMenu"
            anchorEl={anchorEl1}
            open={open1}
            onClose={() => handleClose(1)}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem>
              <Link
                style={{
                  width: "100%",
                  fontSize: "1.8rem",
                  textDecoration: "none",
                  color: palette.black,
                }}
                className="link"
                to="/about-us"
              >
                {t("whoWeAre")}
              </Link>
            </MenuItem>
            <MenuItem>
              <Link
                style={{
                  width: "100%",
                  fontSize: "1.8rem",
                  textDecoration: "none",
                  color: palette.black,
                }}
                className="link"
                to="/carbon-neutrality"
              >
                {t("carbonNeutrality")}
              </Link>
            </MenuItem>
            {/* <MenuItem>
              <Link
                style={{
                  width: "100%",
                  fontSize: "1.8rem",
                  textDecoration: "none",
                  color: palette.black,
                }}
                className="link"
                to="/contact"
              >
                {t("contact")}
              </Link>
            </MenuItem> */}
            <MenuItem>
              <Link
                style={{
                  width: "100%",
                  fontSize: "1.8rem",
                  textDecoration: "none",
                  color: palette.black,
                }}
                className="link"
                to="/privacy-policy"
              >
                {t("privacy")}
              </Link>
            </MenuItem>
          </Menu>
        </span>
        <Link to="/contact" className="menuItem">{t("contact")}</Link>
        {/* <span className="menuItem" onClick={(e) => handleClick(e, 2)}>
          <span className="resources">
            {t("resources")}
            {!open2 ? (
              <KeyboardArrowDown className="chevron" />
            ) : (
              <KeyboardArrowUp className="chevron" />
            )}
          </span>
          <Menu
            id="firstMenu"
            anchorEl={anchorEl2}
            open={open2}
            onClose={() => handleClose(2)}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem>
              <a
                style={{
                  width: "100%",
                  fontSize: "1.8rem",
                  textDecoration: "none",
                  color: palette.black,
                }}
                className="link"
                href="https://blog.lapieza.io/"
                target="_blank"
                rel="noreferrer"
              >
                {t("Blog")}
              </a>
            </MenuItem>
          </Menu>
        </span> */}
      </div>
      <div className="session">
        <a
          href="https://ats.lapieza.io/login"
          target="_blank"
          rel="noreferrer"
          className="menuItem"
        >
          {t("signIn")}
        </a>
        <a
          href="https://ats.lapieza.io/authorize-me"
          target="_blank"
          rel="noreferrer"
          className="buttonRouge"
        >
          {t("tryFree")}
        </a>
        <Select className="lang" value={language} onChange={onChange}>
          {languages.map((item) => (
            <MenuItem
              style={{ fontSize: "1.5rem" }}
              key={item.key}
              value={item.key}
            >
              {`${countryToFlag(item?.code || "")} ${t(item.key)}`}
            </MenuItem>
          ))}
        </Select>
      </div>
    </nav>
  );
};

export default styled(Navbar)`
  padding: 0 8%;
  @media (max-height: 899px) {
    height: 10vh;
  }
  @media (min-height: 900px) {
    height: 10rem;
  }
  @media (max-height: 600px) {
    height: fit-content;
  }
  display: grid;
  grid-template-columns: 1fr 3fr 4fr;
  justify-items: flex-end;
  align-items: center;
  @media (max-width: 900px) {
    display: none;
  }
  .logo {
    text-align: center;
  }
  .menu {
    ${getFlex("row", "ct", "ct")};
    @media (max-width: 900px) {
      justify-content: center;
    }
    .solutions {
      ${getFlex("row", "ct", "ct")};
      .chevron {
        width: 2.5rem;
        height: 2.5rem;
      }
    }
    .buttonRouge {
      ${linkRouge};
      padding: 1rem 4rem;
    }
    .lang {
      height: 4rem;
      font-size: 2rem;
      font-family: "SofiaProRegularAz", sans-serif;
      border-radius: 2rem;
      border: none;
      text-align: center;
      margin-left: 1rem;
      background-color: ${palette.generalBg};
    }
  }
  .session {
    display: grid;
    grid-template-columns: repeat(3, max-content);
    align-items: center;
    @media (max-width: 900px) {
      justify-content: space-evenly;
    }
    column-gap: 1rem;
    .buttonRouge {
      ${linkRouge};
      padding: 1rem 4rem;
      min-width: fit-content;
    }
    .lang {
      height: 4rem;
      font-size: 2rem;
      border-radius: 2rem;
      border: none;
      text-align: center;
      background-color: ${palette.generalBg};
    }
  }
  .menuItem {
    ${menuItem};
    text-decoration: none;
    @media (max-width: 900px) {
      margin: 0 1rem;
    }
  }
`;
