import { css } from "styled-components";

export const palette = {
  generalBg: "#FCFCFC",
  whiteWhite: "#FFFFFF",
  greenLight: "#6EC39B",
  bgGrey: "#F5F5F5",
  red: "#F25142",
  blue: "#032155",
  black: "#232323",
  gold: "#F5C987",
  grey: "#555555",
  hiringGreen: "#91B8A5",
  greyChevron: "#707070",
};

export const getFlex = (dir, jc, ai) => {
  const values = {
    ct: "center",
    st: "flex-start",
    ed: "flex-end",
    sb: "space-between",
    sa: "space-around",
    se: "space-evenly",
  };

  return css`
    display: flex;
    flex-direction: ${dir};
    justify-content: ${values[jc]};
    align-items: ${values[ai]};
  `;
};

export const buttonRouge = css`
  border-radius: 40px;
  border: none;
  outline: none;
  background-color: ${palette.red};
  color: ${palette.whiteWhite};
  padding: 1rem 3rem;
  font-size: 2rem;
  cursor: pointer;
  min-width: fit-content;
`;

export const buttonBlanc = css`
  border-radius: 40px;
  border: none;
  outline: none;
  background-color: ${palette.whiteWhite};
  color: ${palette.black};
  padding: 1rem 3rem;
  font-size: 2rem;
`;

export const heroButtonRouge = css`
  border-radius: 40px;
  width: 24rem;
  border: none;
  outline: none;
  background-color: ${palette.red};
  color: ${palette.whiteWhite};
  padding: 1rem 3rem;
  font-size: 2rem;
  cursor: pointer;
`;

export const linkRouge = css`
  border-radius: 40px;
  width: 24rem;
  border: none;
  outline: none;
  background-color: ${palette.red};
  color: ${palette.whiteWhite};
  padding: 1rem 3rem;
  font-size: 2rem;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
`;

export const linkBlanc = css`
  border-radius: 40px;
  width: 24rem;
  border: none;
  outline: none;
  background-color: ${palette.whiteWhite};
  color: ${palette.black};
  padding: 1rem 3rem;
  font-size: 2rem;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
`;

export const heroButtonBlanc = css`
  border-radius: 40px;
  width: 24rem;
  border: none;
  outline: none;
  background-color: ${palette.whiteWhite};
  color: ${palette.black};
  padding: 1rem 3rem;
  font-size: 2rem;
  cursor: pointer;
`;

export const menuItem = css`
  border: none;
  background-color: ${palette.generalBg};
  color: ${palette.black};
  cursor: pointer;
  font-size: 2rem;
  margin: 0 1rem;
  min-width: fit-content;
`;

export const singleSlide = css`
  ${getFlex("row", "ct", "ed")};
  position: absolute;
  top: 0;
  min-width: calc(100% / 3);
  width: calc(100% / 3);
  height: 100%;
  cursor: pointer;
  opacity: 0.5;
  transition: all 1s ease;
  bottom: 0;
  .img {
    width: 80%;
    transition: all 1s ease;
  }
`;