import React from "react";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";
import { getFlex, linkRouge, palette } from "../../styles/styles";
import { Link } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";

const Footer = (props) => {
  const { className } = props;
  const { t } = useTranslation("footer");

  return (
    <footer className={className}>
      <div className="company">
        <Link to="/">
          <StaticImage src="../../images/logo.png" alt="" />
        </Link>
        <ul>
          <li>Cordoba 95, Roma Norte - CDMX, 06700</li>
          <li>Copyright © 2021 Talento Startup SAPI de CV</li>
          <li>pol@lapieza.io</li>
          <li>+52 55-6542-2798</li>
        </ul>
      </div>
      <div className="list">
        <h5>{t("solutions")}</h5>
        <ul>
          <li>
            <Link className="link" to="/ats">
              {t("hiringSuite")}
            </Link>
          </li>
          <li>
            <Link className="link" to="/headhunting">
              {t("executiveSearch")}
            </Link>
          </li>
          <li>
            <Link className="link" to="/pre-screening">
              {t("autoHunt")}
            </Link>
          </li>
          <li>
            <Link className="link" to="/digital-campus">
              {t("digitalCampus")}
            </Link>
          </li>
        </ul>
      </div>
      <div className="list">
        <h5>{t("aboutUs")}</h5>
        <ul>
          <li>
            <Link className="link" to="/about-us">
              {t("whoWeAre")}
            </Link>
          </li>
          <li>
            <Link className="link" to="/carbon-neutrality">
              {t("carbon")}
            </Link>
          </li>
          <li>
            <Link className="link" to="/contact">
              {t("contact")}
            </Link>
          </li>
        </ul>
      </div>
      <div className="list">
        <h5>{t("resources")}</h5>
        <ul>
          <li>
            <a
              className="link"
              href="https://blog.lapieza.io/"
              target="_blank"
              rel="noreferrer"
            >
              {t("Blog")}
            </a>
          </li>
          {/* <li>{t("events")}</li>
          <li>{t("press")}</li> */}
        </ul>
      </div>
      <div className="list">
        <h5>{t("legal")}</h5>
        <ul>
          <li>
            <Link className="link" to="/terms-and-conditions">
              T&C
            </Link>
          </li>
          <li>
            <Link className="link" to="/privacy-policy">
              {t("privacy")}
            </Link>
          </li>
          <li>
            <Link className="link" to="/usability">
              {t("usability")}
            </Link>
          </li>
        </ul>
      </div>
      <div className="last">
        <a
          href="https://ats.lapieza.io/authorize-me"
          target="_blank"
          rel="noreferrer"
          className="buttonRouge"
        >
          {t("tryFree")}
        </a>
      </div>
    </footer>
  );
};

export default styled(Footer)`
  padding: 4% 10%;
  display: grid;
  grid-template-columns: 4fr repeat(4, 1.5fr) max-content;
  grid-column-gap: 4rem;
  @media (max-height: 899px) {
    height: 40vh;
  }
  @media (min-height: 900px) {
    height: 40rem;
  }

  .company {
    ul {
      list-style: none;
      margin-top: 2rem;
      padding: 0;

      li {
        font-family: SofiaProLightAz, sans-serif;
        font-size: 1.6rem;
        margin-bottom: 1rem;
        @media (max-width: 600px) {
          text-align: center;
        }
      }
    }
  }

  .list {
    ${getFlex("column", "st", "st")};
    h5 {
      font-size: 2rem;
      margin-bottom: 2rem;
      @media (max-width: 768px) {
        margin: 2rem auto 1rem auto;
      }
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        font-size: 1.6rem;
        margin-bottom: 1rem;
        @media (max-width: 600px) {
          text-align: center;
        }

        .link {
          text-decoration: none;
          color: ${palette.black};
        }
      }
    }
  }

  .last {
    text-align: center;

    .buttonRouge {
      ${linkRouge};
      width: 100%;
    }
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    justify-items: center;
    height: auto;
    & > * {
      text-align: center;
    }
    .list {
      ${getFlex("column", "ct", "ct")};
    }
  }
`;
